import React from 'react';
import { Radio } from 'antd';

const ProductStatusGroupButton = ({ onChange, value, title, icon, conditionIfTrue, conditionIfFalse })  =>{
  return (
    <>
      <div>
        {icon}
        <b>{title}</b>
      </div>
      <div>
        <Radio.Group onChange={onChange} value={value}>
          <Radio.Button value="true">{conditionIfTrue}</Radio.Button>
          <Radio.Button value={null}>All</Radio.Button>
          <Radio.Button value="false">{conditionIfFalse}</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
}

export default ProductStatusGroupButton;
