import React, {useState, useCallback, useEffect, useMemo} from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Tooltip, Tag, message, Table, InputNumber } from 'antd';
import b_ from 'b_';
import TableSettings from "./TableSettings";
import EditRatio from './EditRatio';
import AddMapping from './AddMapping';
import DeleteMapping from './DeleteMapping';
import ActionButtons from './ActionButtons'
import sortArrayRows from '../../Utils/sortArrayRows';
import PopupWithJson from '../../Components/PopupWithJson'
import { changeRatio, resetRatio, changeAction } from '../../Reducers/resell';
import useStorageState from '../../Utils/useStorageState'
import { ExclamationCircleTwoTone } from '@ant-design/icons';

const b = b_.lock('ResellTable');

function ResellTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                    }) {

                      console.log ('FILTERS', filters)

  const dispatch = useDispatch();

  const handleChangeRatio = useCallback((mktId, mktProductId, ratioMin, ratioMax) => {
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      dispatch(changeRatio({ mktId, mktProductId, ratioMin, ratioMax })).then(() => {
        message.success('Ratio has been updated!');
        afterUpdate();
      }).catch(error => {
        console.log(error);
        message.error('Can\'t update ratio.');
      });
    }
  }, [dispatch, afterUpdate]);

  const handleResetRatio = useCallback((mktId, mktProductId) => {
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      dispatch(resetRatio({ mktId, mktProductId })).then(() => {
        message.success('Ratio has been reset!');
        afterUpdate();
      }).catch(error => {
        console.log(error);
        message.error('Can\'t reset ratio.');
      });
    }
  }, [dispatch, afterUpdate]);

  const handleAfterUpdate = useCallback(() => {
    afterUpdate();
  }, [afterUpdate]);

  const handleChangeAction = useCallback((mktId, mktProductId, value) => {
    if (!mktProductId) {
      message.error('Market product ID is absent.');
    } else {
      dispatch(changeAction({ mktId, mktProductId, value })).then(() => {
        message.success('Action has been added!');
        afterUpdate();
      }).catch(error => {
        console.log(error);
        message.error('Can\'t change action.');
      });
    }
  }, [dispatch, afterUpdate]);

  const loadings = useSelector(state => ({
    ratio: state.resell.changeRatio?.isLoading,
  }), shallowEqual);

  const platforms = useSelector((state) => state.resell.platforms.payload, shallowEqual);
  const payload = useSelector((state) => state.resell.list.payload, shallowEqual);
  const markets = useSelector((state) => state.resell.markets.payload, shallowEqual);

  let regionsSet = new Set()
  let namesSet = new Set()

  payload && Object.entries(payload.page).forEach( ([key, value]) => {
    regionsSet.add(value.pltRegion);
    namesSet.add(value.pltProductId)
  })

  let regions = useMemo(() =>[], [payload])

  // console.log('payload', regions)
  
  useEffect(() => {
    regions.length = 0
    regionsSet.forEach(item => regions.push({'value': item, 'text': item}))
  }, [payload]);

  let names = useMemo(() => [], [payload])

  useEffect(() => {
    names.length = 0
    namesSet.forEach(item => names.push({'value': item, 'text': item}))
  }, [payload]);

  const gamivoFilters = (filters.mktId && filters.mktId.includes('3')) || !filters.mktId;
  const g2aFilters = (filters.mktId && filters.mktId.includes('4')) || !filters.mktId;

  // console.log('filtersTABLE', gamivoFilters)

  const GetColumns = useCallback(() => {
    return [
      {
        title: 'Platform',
        dataIndex: 'pltId',
        key: 'pltId',
        width: 150,
        ...(platforms ? {
          render: platform => platforms.map((item) => +item.id === +platform && item.name),        
          // filters: platforms.map((item) => ({value: item.id, text: item.name})),
          // filterMultiple: false,
          // filteredValue: filters['pltId'] || null,
        } : {}),
        sorter: (a, b) => {
          let stringA
          let stringB
          platforms.map((item) => {
            if (+item.id === +a.pltId) stringA = item.name
            else if (+item.id === +b.pltId) stringB = item.name
          })
          return stringA.localeCompare(stringB)
        }
      },
      {
        title: 'Name',
        dataIndex: 'pltProductId',
        key: 'pltProductId',
        width: 140,
        align: 'left',
        className: 'ellipsis',
        render: name => (
          <Tooltip placement="topLeft" title={name}>
            {name}
          </Tooltip>
        ),
        // filterMultiple: true,
        // ...(names? {
        //   filters: [...names],
        //   filterMultiple: true,
        //   filteredValue: filters['pltProductId'] || null,
        // } : {}),
        sorter: (a, b) => a.pltProductId.localeCompare(b.pltProductId),
      },
      {
        title: 'Region',
        dataIndex: 'pltRegion',
        key: 'pltRegion',
        width: 120,
        sorter: (a, b) => a.pltRegion.localeCompare(b.pltRegion),
      },
      {
        title: 'Stock',
        dataIndex: 'inventory.stockSize',
        key: 'inventory.stockSize',
        width: 85,
        render: (_, record) => record.inventory.stockSize,
        defaultSortOrder: 'descend',
        sorter: (a, b) => +a.inventory.stockSize - +b.inventory.stockSize,
      },
      {
        title: 'Sold, not activated',
        dataIndex: 'inventory.soldNotActivated',
        key: 'sold',
        width: 110,
        render: (_, record) => record.inventory.soldNotActivated,
        sorter: (a, b) => +a.inventory.soldNotActivated - +b.inventory.soldNotActivated,
      },
      {
        title: 'Reward',
        dataIndex: 'inventory.reward',
        key: 'inventory.reward',
        width: 92,
        sorter: (a, b) => +a - +b,
        render: (_, record) => record.inventory.reward,
        sorter: (a, b) => +a.inventory.reward - +b.inventory.reward,

      },
      {
        title: 'Statistics',
        children: [
          {
            title: '1',
            dataIndex: 'inventory.sold1',
            key: 'inventory.sold1',
            width: 55,
            render: (_, record) => record.inventory.sold1,
            sorter: (a, b) => +a.inventory.sold1 - +b.inventory.sold1,
          },
          {
            title: '7',
            dataIndex: 'inventory.sold7',
            key: 'inventory.sold7',
            width: 55,
            render: (_, record) => record.inventory.sold7,
            sorter: (a, b) => +a.inventory.sold7 - +b.inventory.sold7,
          },
          {
            title: '30',
            dataIndex: 'inventory.sold30',
            key: 'inventory.sold30',
            width: 55,
            render: (_, record) => record.inventory.sold30,
            sorter: (a, b) => +a.inventory.sold30- +b.inventory.sold30,
          },
        ]
      },
      {
        title: 'Comments',
        dataIndex: 'pltComment',
        key: 'pltComment',
        width: 120,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Price',
        children: [
          {
            title: 'G2A',
            dataIndex: 'mktRows.G2A.price.mktPrice',
            key: 'mktRows[0].price.mktPrice',
            width: 90,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <div key={index} className="inner-row__content">
                        {item?.price?.mktPrice !== undefined && +item.price.mktPrice }
                        {
                          item?.price?.alert
                          && <Tooltip placement="topLeft" title={item.price.alert}>
                            <ExclamationCircleTwoTone twoToneColor="#eb2f96" style={{float: 'right', marginTop: '5px'}}/>
                          </Tooltip>
                        }
                      </div>
                    )}
              </div>
            },
          },
          {
            title: 'Diff',
            dataIndex: 'mktRows.G2A.price.mktPrice.diff',
            key: 'mktRows[0].price.mktPrice_Diff',
            width: 70,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => {
                        let diff
                        if (item?.price?.mktPrice && item?.competitors) {
                          if (Math.sign(+item.competitors.min.mktPrice - +item.price.mktPrice) === -1) {
                            diff = <Tag color="red">{(+item.competitors.min.mktPrice - +item.price.mktPrice).toFixed(2)}</Tag>
                          } else {
                            diff = (+item.competitors.min.mktPrice - +item.price.mktPrice).toFixed(2)
                          }
                        } else diff = ``
                        return <span key={index} className="inner-row__content">{diff}</span>
                      }
                    )}
              </div>
            }
          },
          {
            title: 'Сompetitors',
            dataIndex: 'mktRows.G2A.competitors.min.mktPrice',
            key: 'mktRows[0].competitors.min.mktPrice_Сompetitors',
            width: 125,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <span key={index} className="inner-row__content">{item?.competitors?.min?.mktPrice }</span>)}
              </div>
            }
          },
          {
              title: 'GMVO',
              dataIndex: 'mktRows.GMVO.price.mktPrice',
              key: 'mktRows[1].price.mktPrice',
              width: 90,
              className: 'inner-rows',
              render: (_, record) => {
                const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
                return <div className="inner-rows__wrapper">
                      { rows.map((item, index) => <div key={index} className="inner-row__content">
                          {item?.price?.mktPrice !== undefined && +item.price.mktPrice }
                          {
                            item?.price?.alert
                            && <Tooltip placement="topLeft" title={item.price.alert}>
                              <ExclamationCircleTwoTone twoToneColor="#eb2f96" style={{float: 'right', marginTop: '5px'}}/>
                            </Tooltip>
                          }
                        </div>)}
                </div>
              },
            },{
              title: 'Diff',
              dataIndex: 'mktRows.GMVO.price.mktPrice.diff',
              key: 'mktRows[1].price.mktPrice_Diff',
              width: 70,
              className: 'inner-rows',
              render: (_, record) => {
                const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
                return <div className="inner-rows__wrapper">
                      { rows.map((item, index) => {
                          let diff
                          if (item?.price?.mktPrice && item?.competitors) {
                            if (Math.sign(+item.competitors.min.mktPrice - +item.price.mktPrice) === -1) {
                              diff = <Tag color="red">{(+item.competitors.min.mktPrice - +item.price.mktPrice).toFixed(2)}</Tag>
                            } else {
                              diff = (+item.competitors.min.mktPrice - +item.price.mktPrice).toFixed(2)
                            }
                          } else diff = ``
                          return <span key={index} className="inner-row__content">{diff}</span>
                        }
                      )}
                </div>
              },
            },{ 
              title: 'Сompetitors',
              dataIndex: 'mktRows.GMVO.competitors.min.mktPrice',
              key: 'mktRows[1].competitors.min.mktPrice_Сompetitors',
              width: 125,
              className: 'inner-rows',
              render: (_, record) => {
                const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
                return <div className="inner-rows__wrapper">
                      { rows.map((item, index) => <span key={index} className="inner-row__content">{item?.competitors?.min?.mktPrice }</span>)}
                </div>
              }
            },
            {
              title: 'Kinguin',
              dataIndex: 'mktRows.kinguin.price.mktPrice',
              key: 'mktRows[2].price.mktPrice',
              width: 90,
              className: 'inner-rows',
              render: (_, record) => {
                const rows = (record.mktRows && sortArrayRows(record.mktRows, 'KINGUIN', markets)) || [{}]
                return <div className="inner-rows__wrapper">
                      { rows.map((item, index) => <div key={index} className="inner-row__content">
                          {item?.price?.mktPrice !== undefined && +item.price.mktPrice }
                          {
                            item?.price?.alert
                            && <Tooltip placement="topLeft" title={item.price.alert}>
                              <ExclamationCircleTwoTone twoToneColor="#eb2f96" style={{float: 'right', marginTop: '5px'}}/>
                            </Tooltip>
                          }
                        </div>
                      )}
                </div>
              },
            },
            {
              title: 'Diff',
              dataIndex: 'mktRows.kinguin.price.mktPrice.diff',
              key: 'mktRows[2].price.mktPrice_Diff',
              width: 70,
              className: 'inner-rows',
              render: (_, record) => {
                const rows = (record.mktRows && sortArrayRows(record.mktRows, 'KINGUIN', markets)) || [{}]
                return <div className="inner-rows__wrapper">
                      { rows.map((item, index) => {
                          let diff
                          if (item?.price?.mktPrice && item?.competitors) {
                            if (Math.sign(+item.competitors.min.mktPrice - +item.price.mktPrice) === -1) {
                              diff = <Tag color="red">{(+item.competitors.min.mktPrice - +item.price.mktPrice).toFixed(2)}</Tag>
                            } else {
                              diff = (+item.competitors.min.mktPrice - +item.price.mktPrice).toFixed(2)
                            }
                          } else diff = ``
                          return <span key={index} className="inner-row__content">{diff}</span>
                        }
                      )}
                </div>
              }
            },{
              title: 'Сompetitors',
              dataIndex: 'mktRows.kinguin.competitors.min.mktPrice',
              key: 'mktRows[2].competitors.min.mktPrice_Сompetitors',
              width: 125,
              className: 'inner-rows',
              render: (_, record) => {
                const rows = (record.mktRows && sortArrayRows(record.mktRows, 'KINGUIN', markets)) || [{}]
                return <div className="inner-rows__wrapper">
                      { rows.map((item, index) => <span key={index} className="inner-row__content">{item?.competitors?.min?.mktPrice }</span>)}
                </div>
              }
            }
        ]
      },
      {
        title: 'Profit',
        children: [
          {
            title: 'G2A',
            dataIndex: 'G2A.formula.currentProfit',
            key: 'mktRows[0].formula.currentProfit',
            width: 70,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <span key={index} className="inner-row__content">{item?.formula?.currentProfit}</span>)}
              </div>
            }
          },
         {
            title: 'GMVO',
            dataIndex: 'GMVO.formula.currentProfit',
            key: 'mktRows.GMVO.formula.currentProfit',
            width: 80,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <span key={index} className="inner-row__content">{item?.formula?.currentProfit}</span>)}
              </div>
            }
          },
          {
            title: 'Kinguin',
            dataIndex: 'kinguin.formula.currentProfit',
            key: 'mktRows.kinguin.formula.currentProfit',
            width: 80,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <span key={index} className="inner-row__content">{item?.formula?.currentProfit}</span>)}
              </div>
            }
          },
        ]
      },
      {
        title: 'Ratio',
        editable: true,
        children: [
          {
            title: 'G2A',
            dataIndex: 'G2A.price.ext.ratioMin',
            key: 'mktRows[0].price.ext.ratioMin',
            colSpan: 2,
            width: 70,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <EditRatio
                          key={index}
                          handleSave={(newRatio) => handleChangeRatio(4, item?.mktProductId, newRatio[0], newRatio[1])}
                          handleReset={() => handleResetRatio(4, item?.mktProductId)}
                          title="Update ratio"
                          isLoading={loadings.ratio || loading}
                          // titlePopover="Edit ratio"
                          initialValueMin={item?.price?.ext?.ratioMin}
                          initialValueMax={item?.price?.ext?.ratioMax}
                          changeBlockMin={({onChange, ...props}) => (
                            <InputNumber {...props}
                              size="small"
                              onChange={e => onChange(e)}
                              defaultValue="0"
                              min="0"
                              max="10"
                              step="0.1"
                            />
                          )}
                          changeBlockMax={({onChange, ...props}) => (
                            <InputNumber {...props}
                                size="small"
                                onChange={e => onChange(e)}
                                defaultValue="0"
                                min="0"
                                max="10"
                                step="0.1"
                            />
                          )}
                      >
                          <span>{item?.price?.ext?.ratioMin}</span>
                      </EditRatio>
                    )}
              </div>}
          },
          {
            title: 'G2A',
            dataIndex: 'G2A.price.ext.ratioMax',
            key: 'mktRows[0].price.ext.ratioMax',
            colSpan: 0,
            width: 70,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => {
                      return <EditRatio
                                key={index}
                                handleSave={(newRatio) => handleChangeRatio(4, item?.mktProductId, newRatio[0], newRatio[1])}
                                handleReset={() => handleResetRatio(4, item?.mktProductId)}
                                title="Update ratio"
                                isLoading={loadings.ratio || loading}
                                // titlePopover="Edit ratio"
                                initialValueMin={item?.price?.ext?.ratioMin}
                                initialValueMax={item?.price?.ext?.ratioMax}
                                changeBlockMin={({onChange, ...props}) => (
                                  <InputNumber {...props}
                                      size="small"
                                      onChange={e => onChange(e)}
                                      defaultValue="0"
                                      min="0"
                                      max="10"
                                      step="0.1"
                                  />
                                )}
                                changeBlockMax={({onChange, ...props}) => (
                                  <InputNumber {...props}
                                      size="small"
                                      onChange={e => onChange(e)}
                                      defaultValue="0"
                                      min="0"
                                      max="10"
                                      step="0.1"
                                  />
                                )}
                            >
                                <span>{item?.price?.ext?.ratioMax}</span>
                            </EditRatio>
                      })}
              </div>}
          },
          {
            title: 'GMVO',
            dataIndex: 'GMVO.price.ext.ratioMin',
            key: 'mktRows[1].item.price.ext.ratioMin',
            colSpan: 2,
            width: 70,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                { rows.map((item, index) => {
                  return <EditRatio
                            key={index}
                            handleSave={(newRatio) => handleChangeRatio(3, item?.mktProductId, newRatio[0], newRatio[1])}
                            handleReset={() => handleResetRatio(3, item?.mktProductId)}
                            title="Update ratio"
                            isLoading={loadings.ratio || loading}
                            // titlePopover="Edit ratio"
                            initialValueMin={item?.price?.ext?.ratioMin}
                            initialValueMax={item?.price?.ext?.ratioMax}
                            changeBlockMin={({onChange, ...props}) => (
                              <InputNumber {...props}
                                  size="small"
                                  onChange={e => onChange(e)}
                                  defaultValue="0"
                                  min="0"
                                  max="10"
                                  step="0.1"
                              />
                            )}
                            changeBlockMax={({onChange, ...props}) => (
                              <InputNumber {...props}
                                  size="small"
                                  onChange={e => onChange(e)}
                                  defaultValue="0"
                                  min="0"
                                  max="10"
                                  step="0.1"
                              />
                            )}
                        >
                            <span>{item?.price?.ext?.ratioMin}</span>
                        </EditRatio>
                  })}
              </div>}
          },
          {
            title: 'GMVO',
            dataIndex: 'GMVO.price.ext.ratioMax',
            key: 'mktRows[1].item.price.ext.ratioMax',
            colSpan: 0,
            width: 70,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => {
                      return <EditRatio
                                key={index}
                                handleSave={(newRatio) => handleChangeRatio(3, item?.mktProductId, newRatio[0], newRatio[1])}
                                handleReset={() => handleResetRatio(3, item?.mktProductId)}
                                title="Update ratio"
                                isLoading={loadings.ratio || loading}
                                // titlePopover="Edit ratio"
                                initialValueMin={item?.price?.ext?.ratioMin}
                                initialValueMax={item?.price?.ext?.ratioMax}
                                changeBlockMin={({onChange, ...props}) => (
                                  <InputNumber {...props}
                                      size="small"
                                      onChange={e => onChange(e)}
                                      defaultValue="0"
                                      min="0"
                                      max="10"
                                      step="0.1"
                                  />
                                )}
                                changeBlockMax={({onChange, ...props}) => (
                                  <InputNumber {...props}
                                      size="small"
                                      onChange={e => onChange(e)}
                                      defaultValue="0"
                                      min="0"
                                      max="10"
                                      step="0.1"
                                  />
                                )}
                            >
                                <span>{item?.price?.ext?.ratioMax}</span>
                            </EditRatio>
                      })}
              </div>}
          },
          {
            title: 'Kinguin',
            dataIndex: 'kinguin.price.ext.ratioMin',
            key: 'mktRows[2].item.price.ext.ratioMin',
            colSpan: 2,
            width: 70,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'KINGUIN', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                { rows.map((item, index) => {
                  return <EditRatio
                            key={index}
                            handleSave={(newRatio) => handleChangeRatio(7, item?.mktProductId, newRatio[0], newRatio[1])}
                            handleReset={() => handleResetRatio(7, item?.mktProductId)}
                            title="Update ratio"
                            isLoading={loadings.ratio || loading}
                            // titlePopover="Edit ratio"
                            initialValueMin={item?.price?.ext?.ratioMin}
                            initialValueMax={item?.price?.ext?.ratioMax}
                            changeBlockMin={({onChange, ...props}) => (
                              <InputNumber {...props}
                                  size="small"
                                  onChange={e => onChange(e)}
                                  defaultValue="0"
                                  min="0"
                                  max="10"
                                  step="0.1"
                              />
                            )}
                            changeBlockMax={({onChange, ...props}) => (
                              <InputNumber {...props}
                                  size="small"
                                  onChange={e => onChange(e)}
                                  defaultValue="0"
                                  min="0"
                                  max="10"
                                  step="0.1"
                              />
                            )}
                        >
                            <span>{item?.price?.ext?.ratioMin}</span>
                        </EditRatio>
                  })}
              </div>}
          },
          {
            title: 'Kinguin',
            dataIndex: 'kinguin.price.ext.ratioMax',
            key: 'mktRows[2].item.price.ext.ratioMax',
            colSpan: 0,
            width: 70,
            className: 'inner-rows',
            sorter: (a, b) => a - b,
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'KINGUIN', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => {
                      return <EditRatio
                                key={index}
                                handleSave={(newRatio) => handleChangeRatio(7, item?.mktProductId, newRatio[0], newRatio[1])}
                                handleReset={() => handleResetRatio(7, item?.mktProductId)}
                                title="Update ratio"
                                isLoading={loadings.ratio || loading}
                                // titlePopover="Edit ratio"
                                initialValueMin={item?.price?.ext?.ratioMin}
                                initialValueMax={item?.price?.ext?.ratioMax}
                                changeBlockMin={({onChange, ...props}) => (
                                  <InputNumber {...props}
                                      size="small"
                                      onChange={e => onChange(e)}
                                      defaultValue="0"
                                      min="0"
                                      max="10"
                                      step="0.1"
                                  />
                                )}
                                changeBlockMax={({onChange, ...props}) => (
                                  <InputNumber {...props}
                                      size="small"
                                      onChange={e => onChange(e)}
                                      defaultValue="0"
                                      min="0"
                                      max="10"
                                      step="0.1"
                                  />
                                )}
                            >
                                <span>{item?.price?.ext?.ratioMax}</span>
                            </EditRatio>
                      })}
              </div>}
          },
        ]
      },
      {
        title: 'Market product ID',
        children: [
          {
            title: 'G2A',
            dataIndex: 'mktRows.G2A.mktProductId',
            key: 'mktRows[0].mktProductId',
            width: 200,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              const currentPlatform = platforms && platforms.find((item) => +item.id === +record.pltId).name    
              return <div className="inner-rows__wrapper">
                    { rows.length
                      ? rows.map((item, index) => <a key={index} href={item.mktUrl} target="_blank" rel="noopener noreferrer" className="inner-row__content">
                          {item?.mktProductId}
                          <AddMapping
                            market="G2A"
                            platform={currentPlatform}
                            record={[4, record.pltId, record.pltProductId, record.pltRegion]}
                            afterUpdate={handleAfterUpdate}
                          />
                          <DeleteMapping
                            record={[4, item.mktProductId]}
                            afterUpdate={handleAfterUpdate}
                          />
                      </a>)
                      : <span className="inner-row__content">
                          <AddMapping
                            market="G2A"
                            platform={currentPlatform}
                            record={[4, record.pltId, record.pltProductId, record.pltRegion]}
                            afterUpdate={handleAfterUpdate}
                          />
                        </span>
                      }
              </div>
            }
          },
           {
            title: 'GMVO',
            dataIndex: 'mktRows.GMVO.mktProductId',
            key: 'mktRows[1].mktProductId',
            width: 150,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
              const currentPlatform = platforms && platforms.find((item) => +item.id === +record.pltId).name    

              return <div className="inner-rows__wrapper">
                    { rows.length
                      ? rows.map((item, index) => <div className='market_row'><a key={index} href={item.mktUrl} target="_blank" rel="noopener noreferrer">
                          {item?.mktProductId} 
                      </a><AddMapping
                            market="Gamivo"
                            platform={currentPlatform}
                            record={[3, record.pltId, record.pltProductId, record.pltRegion]}
                            afterUpdate={handleAfterUpdate}
                          />
                          <DeleteMapping
                            record={[3, item.mktProductId]}
                            afterUpdate={handleAfterUpdate}
                          /></div>)
                      : <span className="inner-row__content">
                          <AddMapping
                            market="Gamivo"
                            platform={currentPlatform}
                            record={[3, record.pltId, record.pltProductId, record.pltRegion]}
                            afterUpdate={handleAfterUpdate}
                          />
                      </span>
                      }
              </div>
            }
          },
          {
            title: 'Kinguin',
            dataIndex: 'mktRows.kinguin.mktProductId',
            key: 'mktRows[2].mktProductId',
            width: 300,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'KINGUIN', markets)) || [{}]
              const currentPlatform = platforms && platforms.find((item) => +item.id === +record.pltId).name    
              return <div className="inner-rows__wrapper">
                    { rows.length
                      ? rows.map((item, index) => <div className='market_row'><a key={index} href={item.mktUrl} target="_blank" rel="noopener noreferrer" >
                          {item?.mktProductId}
                          
                      </a>
                      <AddMapping
                      market="Kinguin"
                      platform={currentPlatform}
                      record={[7, record.pltId, record.pltProductId, record.pltRegion]}
                      afterUpdate={handleAfterUpdate}
                    />
                    <DeleteMapping
                      record={[7, item.mktProductId]}
                      afterUpdate={handleAfterUpdate}
                    />
                    </div>)
                      : <span className="inner-row__content">
                          <AddMapping
                            market="Kinguin"
                            platform={currentPlatform}
                            record={[7, record.pltId, record.pltProductId, record.pltRegion]}
                            afterUpdate={handleAfterUpdate}
                          />
                        </span>
                      }
              </div>
            }
          },
        ]
      },
      {
        title: 'Parsing',
        children: [
          {
            title: 'G2A',
            dataIndex: 'mktRows.G2A.mktActive',
            key: 'mktRows[0].mktActive',
            width: 150,
            align: 'center',
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <span key={index} className="inner-row__content">{!item.mktActive ? <Tag color="red">0</Tag> : '1'}</span>)}
              </div>
            }
          },
         {
            title: 'GMVO',
            dataIndex: 'mktRows.GMVO.mktActive',
            key: 'mktRows[1].mktActive',
            width: 150,
            align: 'center',
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <span key={index} className="inner-row__content">{!item.mktActive ? <Tag color="red">0</Tag> : '1'}</span>)}
              </div>
            }
          },
          {
            title: 'Kinguin',
            dataIndex: 'mktRows.kinguin.mktActive',
            key: 'mktRows[2].mktActive',
            width: 150,
            align: 'center',
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'KINGUIN', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                    { rows.map((item, index) => <span key={index} className="inner-row__content">{!item.mktActive ? <Tag color="red">0</Tag> : '1'}</span>)}
              </div>
            }
          },
        ]
      },
      {
        title: 'Action',
        children: [
          {
            title: 'G2A',
            dataIndex: 'mktRows.G2A.isActive',
            key: 'mktRows[0].action',
            width: 120,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'G2A', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                { rows.map((item, index) => <span key={index} className="inner-row__content">
                    <ActionButtons
                      value={item?.isActive?.toString() || ''}
                      handleClick={(value) => handleChangeAction(item?.mktId, item?.mktProductId,  value === "true")}
                    />
                  </span>)}
              </div>
            },
          },
          {
            title: 'GMVO',
            dataIndex: 'mktRows.GMVO.isActive',
            key: 'mktRows[1].action',
            width: 120,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'GMVO', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                { rows.map((item, index) => <span key={index} className="inner-row__content">
                    <ActionButtons
                      value={item?.isActive?.toString() || ''}
                      handleClick={(value) => handleChangeAction(item?.mktId, item?.mktProductId, value === "true")}
                    />
                  </span>)}
              </div>
            },
          },
          {
            title: 'Kinguin',
            dataIndex: 'mktRows.kinguin.isActive',
            key: 'mktRows[2].action',
            width: 120,
            className: 'inner-rows',
            render: (_, record) => {
              const rows = (record.mktRows && sortArrayRows(record.mktRows, 'KINGUIN', markets)) || [{}]
              return <div className="inner-rows__wrapper">
                { rows.map((item, index) => <span key={index} className="inner-row__content">
                    <ActionButtons
                      value={item?.isActive?.toString() || ''}
                      handleClick={(value) => handleChangeAction(item?.mktId, item?.mktProductId, value === "true")}
                    />
                  </span>)}
              </div>
            },
          },
        ]
      },
      {
        title: '...',
        dataIndex: 'json',
        key: 'json',
        width: 30,
        render: (_, record) => <PopupWithJson title="..." content={record} />
      },
    ]; 
  }, [filters, handleChangeRatio, handleResetRatio, loading, loadings.ratio, names, platforms, regions])

  const [columns, setColumns] = useState(GetColumns());
  
  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('resell_table', {
      checkedList: Checkboxs,
      indeterminate: false,
      checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
      setAllCheckbox({
          checkedList,
          indeterminate: !!checkedList.length && checkedList.length < columns.length,
          checkAll: checkedList.length === columns.length,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Checkboxs]);

  useEffect(() => {
    let allCheckList = []
    if(list.length && !Checkboxs.length) {
        GetColumns().forEach((e) => {
            allCheckList.push( e[Object.keys(e)[1]])
            setCheckbox(allCheckList)
        })
    }
  }, [list, AllCheckbox, Checkboxs.length, GetColumns])

  useEffect(() => {
      if(AllCheckbox.checkAll) {
          setAllCheckbox({
              checkedList: GetColumns().map(e => e.title),
              indeterminate: false,
              checkAll: true,
          })
      }
  },[AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
      setAllCheckbox({
          checkedList: (e.target.checked && columns.map(e => e.title)) || [],
          indeterminate: false,
          checkAll: e.target.checked,
      });
      //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Checkboxs]);

  useEffect(() => {
      setColumns(GetColumns())
  }, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = Object.keys(loadings).some(e => !!loadings[e]) || loading

  // console.log('LIST ======>', list)
  
  return <Table
            className={b()}
            bordered
            dataSource={list}
            scroll={{ x: "max-content" }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            rowKey={(record) => `${record.pltId}_${record.pltProductId}_${record.pltRegion}`}
            rowClassName={() => b('lock')}
            title={() => <TableSettings
                onChangeCheckAll={onChangeCheckAll}
                allChecks={AllCheckbox}
                Checkboxs={AllCheckbox.checkedList}
                TableColumn={() =>GetColumns()}
                setCheckbox={onChangeCheckList}
            />}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default ResellTable;
