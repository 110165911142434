import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber} from 'antd';
import { EditFilled } from '@ant-design/icons';
import b_ from 'b_';
import { changeRatios } from '../../../Reducers/resell';

const EditRatios = ({
                     afterUpdate,
                     selected 
                   }) => {

  const b = b_.lock('changeRatios');

  const dispatch = useDispatch();

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const handleSuccess = useCallback((data) => {
    const rowsArr = selected.map((item) => {
      const rowArr = item.split('_')
      return {
        'pltId': +rowArr[0],
        'pltProductId': rowArr[1],
        'pltRegion': rowArr[2],
        ...data
      }
    }) 

    dispatch(changeRatios(rowsArr)).then(() => {
      message.success('Ratios have been changed!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t edit ratios.');
    });
    close();
  }, [dispatch, afterUpdate, close, selected]);
  
  return (
    <>
      <Drawer visible={isOpen} title="Edit ratio" width={300} closable placement="right" onClose={close}>
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            label="New minimum ratio"
            name="minRatio"  
            rules={[
              {
                required: true,
                message: 'please enter new minimum ratio',
              },
          ]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="New maximum ratio"
            name="maxRatio"  
            rules={[
              {
                required: true,
                message: 'please enter new maximum ratio',
              },
          ]}>
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Button
        type="primary"
        disabled={!selected.length}
        icon={<EditFilled />}
        onClick={open}
      >
        Edit ratio for {selected.length} {selected.length === 1 ? 'product' : 'products'}
      </Button>
    </>
  );
};

export default React.memo(EditRatios);
