import React from 'react';
import { Typography } from 'antd';
import b_ from 'b_';
// import './styles.scss';
import Settings from './Settings';

const b = b_.lock('DashboardSettings');
const { Title } = Typography;

function DashboardSettings() {
  return (
    <div className={b()}>
      <Title>Dashboard Settings</Title>
      <div className={b('layout')}>
        <Settings b={b} />
      </div>
    </div>
  );
}

export default DashboardSettings;
