import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Popconfirm, Button, message } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { resetRatios } from '../../../Reducers/resell';


const ResetRatios = ({
                     afterUpdate,
                     selected 
                   }) => {

  const dispatch = useDispatch();

  const submit = useCallback(() => {
    const rowsArr = selected.map((item) => {
      const rowArr = item.split('_')

      return {
        'pltId': +rowArr[0],
        'pltProductId': rowArr[1],
        'pltRegion': rowArr[2],
        'setDefault': ["ratio"]
      }
    }) 

    dispatch(resetRatios(rowsArr)).then(() => {
      message.success('Ratios have been reset!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t reset ratios.');
    });
  }, [dispatch, afterUpdate, selected]);
  
  return (
    <>
      <Popconfirm
        title={`Are you sure to reset ${selected.length} ${selected.length === 1 ? 'product' : 'products'}?`}
        onConfirm={submit}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="primary"
          disabled={!selected.length}
          icon={<UndoOutlined />}
        >
          Reset ratio for {selected.length} {selected.length === 1 ? 'product' : 'products'}
        </Button>
      </Popconfirm>
    </>
  );
};

export default React.memo(ResetRatios);
