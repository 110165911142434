import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import ResellService from '../Services/ResellService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const resellListFetchReset = createAction('resell/list-RESET')

export const resellListFetch = createAction('resell/list', (params) => ({
  payload: ResellService.fetchOrderedList(params),
}))

export const resellPlatformsFetch = createAction('resell/platforms', () => ({
  payload: ResellService.fetchPlatformsList(),
}))

export const resellMarketsFetch = createAction('resell/markets', () => ({
  payload: ResellService.fetchMarketsList(),
}))

export const statusFetch = createAction('resell/status', () => ({
  payload: ResellService.fetchStatus(),
}))

export const regionsFetch = createAction('resell/regions', () => ({
  payload: ResellService.fetchRegions(),
}))

export const changeRatio = createAction('resell/update-ratio', (param) => ({
  payload: ResellService.changeRatio(param),
}));

export const resetRatio = createAction('resell/reset-ratio', (param) => ({
  payload: ResellService.resetRatio(param),
}));

export const addMapping = createAction('resell/add-mapping', (param) => ({
  payload: ResellService.addMapping(param),
}));

export const deleteMapping = createAction('resell/delete-mapping', (param) => ({
  payload: ResellService.deleteMapping(param),
}));


export const changeAction = createAction('resell/change-action', (param) => ({
  payload: ResellService.setAction(param),
}));

export const changeRatios = createAction('resell/change-ratios', (param) => ({
  payload: ResellService.changeRatios(param),
}));

export const resetRatios = createAction('resell/reset-ratios', (param) => ({
  payload: ResellService.resetRatios(param),
}));

export const changeActions = createAction('resell/change-actions', (param) => ({
  payload: ResellService.setActions(param),
}));

export const changeActionsG2A = createAction('resell/change-actions-G2A', (param) => ({
  payload: ResellService.setActionsG2A(param),
}));

export const changeActionsGamivo = createAction('resell/change-actions-Gamivo', (param) => ({
  payload: ResellService.setActionsGamivo(param),
}));

export const settingsFetch = createAction('resell/settings-fetch', (data) => ({
  payload: ResellService.fetchSettings(data)
}))

export const settingsFetchReset = createAction('resell/settings-fetch-reset')

export const settingsSchemaFetch = createAction('resell/settings-schema-fetch', (data) => ({
  payload: ResellService.fetchSettingsSchema(data)
}))

export const settingsUiSchemaFetch = createAction('resell/settings-ui-schema-fetch', (data) => ({
  payload: ResellService.fetchSettingsUISchema(data)
}))

export const settingsUpdate = createAction('resell/settings-update', (data) => ({
  payload: ResellService.updateSettings(data)
}))

export const settingsUpdateReset = createAction('resell/settings-update-reset');

export const lookupKinguin = createAction('lookKinguin', (q) => ({
  payload: ResellService.kinguinLookup(q),
}));

const initState = {
  listFetchReset: '',
  list: getDefaultState(),
  platforms: getDefaultState(),
  markets: getDefaultState(),
  ratio: getDefaultState(),
  resetRatio: getDefaultState(),
  addMapping: getDefaultState(),
  deleteMapping: getDefaultState(),
  changeAction: getDefaultState(),
  changeRatios: getDefaultState(),
  resetRatios: getDefaultState(),
  changeActions: getDefaultState(),
  changeActionsG2A: getDefaultState(),
  changeActionsGamivo: getDefaultState(),
  status: getDefaultState(),
  regions: getDefaultState(),
  settingsFetch: getDefaultState(),
  settingsFetchReset: getDefaultState(),
  settingsSchemaFetch: getDefaultState(),
  settingsUiSchemaFetch: getDefaultState(),
  settingsUpdate: getDefaultState(),
  settingsUpdateReset: getDefaultState(),
  lookKinguin: getDefaultState(),
};

export default createReducer(initState, {
  [resellListFetchReset]: (state, action) => ({ ...state, listFetchReset: action.payload }),
  ...getDefaultHandler(resellListFetch, 'list'),
  ...getDefaultHandler(resellPlatformsFetch, 'platforms'),
  ...getDefaultHandler(resellMarketsFetch, 'markets'),
  ...getDefaultHandler(changeRatio, 'ratio'),
  ...getDefaultHandler(resetRatio, 'resetRatio'),
  ...getDefaultHandler(addMapping, 'addMapping'),
  ...getDefaultHandler(deleteMapping, 'deleteMapping'),
  ...getDefaultHandler(changeAction, 'changeAction'),
  ...getDefaultHandler(changeRatios, 'changeRatios'),
  ...getDefaultHandler(resetRatios, 'resetRatios'),
  ...getDefaultHandler(changeActions, 'changeActions'),
  ...getDefaultHandler(changeActionsG2A, 'changeActionsG2A'),
  ...getDefaultHandler(changeActionsGamivo, 'changeActionsGamivo'),
  ...getDefaultHandler(statusFetch, 'status'),
  ...getDefaultHandler(regionsFetch, 'regions'),
  ...getDefaultHandler(settingsFetch, 'settingsFetch'),
  ...getDefaultHandler(settingsFetchReset, 'settingsFetchReset'),
  ...getDefaultHandler(settingsSchemaFetch, 'settingsSchemaFetch'),
  ...getDefaultHandler(settingsUiSchemaFetch, 'settingsUiSchemaFetch'),
  ...getDefaultHandler(settingsUpdate, 'settingsUpdate'),
  ...getDefaultHandler(settingsUpdateReset, 'settingsUpdateReset'),
  ...getDefaultHandler(lookupKinguin, 'lookKinguin'),
});
