import React, { useCallback, useEffect, useState } from 'react';
import useBoolean from '../../Utils/useBoolean';
import { Popover, Button } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import LoadingBlock from '../../Components/LoadingBlock';

const EditRatio = ({
                           children,
                           changeBlockMin,
                           changeBlockMax,
                           handleSave,
                           handleReset,
                           initialValueMin,
                           initialValueMax,
                           isLoading,
                           title,
                           titlePopover,
                           popoverContentStyle,
                        }) => {
  const { value: showPopover, setFalse: closePopover, setValue: handleVisibleChange } = useBoolean(false);

  const [inputValue, handleChange] = useState([initialValueMin, initialValueMax]);

  const handleClosePopover = useCallback(() => {
    closePopover();
    handleChange([initialValueMin, initialValueMax]);
  }, [initialValueMin, initialValueMax, closePopover]);

  useEffect(() => {
    closePopover();
    handleChange([initialValueMin, initialValueMax]);
  }, [initialValueMin, initialValueMax, closePopover, handleSave, handleReset]);

  const content = (
    <LoadingBlock loading={isLoading} noMinHeight>
      <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '4px' }}>min</span>
          {changeBlockMin({
            value: inputValue[0],
            onChange: (v) => handleChange([+v, inputValue[1]]),
          })}
          <span style={{ marginLeft: '6px', marginRight: '4px' }}>max</span>
          {changeBlockMax({
            value: inputValue[1],
            onChange: (v) => handleChange([inputValue[0], +v]),
          })}
        <div className="ml-small flex-ai-center" style={{display: "flex", marginLeft: '15px'}}>
          <Button
              size="small"
              variant="contained"
              color="default"
              onClick={handleClosePopover}>
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            style={(inputValue[1] !== initialValueMax || inputValue[0] !== initialValueMin) ? { backgroundColor: "#1976d2", color: "#fff", borderColor: "#1976d2"} : {}}
            variant="contained"
            onClick={() => handleSave(inputValue)}
            disabled={inputValue[1] === initialValueMax && inputValue[0] === initialValueMin}
            className="ml-small"
          >
            Save
          </Button>
          <Button
              size="small"
              variant="contained"
              color="default"
              onClick={handleReset}
              className="ml-small">
            Reset
          </Button>
        </div>
      </div>
    </LoadingBlock>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={showPopover}
      onVisibleChange={handleVisibleChange}
      title={ title ? title : null}
    >
      <span style={{ cursor: 'pointer', position: 'relative', display: 'block', minWidth: '40px', ...popoverContentStyle }}>
        <Popover content={titlePopover || false}>
          {children}
          <EditTwoTone className="ResellTable__edit" />
        </Popover>
      </span>
    </Popover>
  );
};

export default React.memo(EditRatio);
