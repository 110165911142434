const marketsToOptions = (mktsNames, mkts) => {
  let options
  if (mktsNames) {
    return mktsNames.map((item) => (
      { ...options, value: `${mkts[item].toString()}`, label: item }
    ))
  }
  return [{ value: 'loading', label: 'loading' }]

}

export default marketsToOptions
