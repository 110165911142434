import React, { useEffect } from 'react';
import { Select } from 'antd';
import { LanguageTwoTone, StoreMallDirectory } from '@mui/icons-material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { regionsFetch } from '../../../Reducers/resell';
import transformArrayToOptions from '../../../Utils/platformsToOptions';

const { Option } = Select;

const SelectPlatform = ({ onChange, value }) => {

  const dispatch = useDispatch();

  const { payload } = useSelector((state) => state.resell.platforms, shallowEqual);

  const optionsData = payload && transformArrayToOptions(payload)

  // console.log('payloadplatform', payload)

  return (
    <>
      <div>
      <StoreMallDirectory style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>Platforms</b>
      </div>
      <div>
       <Select
          value={value}
          mode="multiple"
          style={{ width: '75%' }}
          placeholder="Select"
          onChange={onChange}
          options={optionsData}
        >
          {/* {payload && payload?.map((platform, index) => (
            <Option key={index} value={platform.id}>{platform.name}</Option>
          ))} */}
        </Select>
      </div>
    </>
  );
}

export default SelectPlatform;
