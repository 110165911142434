import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Row } from 'antd';
import LoadingBlock from '../LoadingBlock';
import {
  formDataToUrlData,
  urlFiltersToFormData,
} from '../../Utils/filtersControls';
import useForm from 'antd/lib/form/hooks/useForm';
import { LockTwoTone, UndoOutlined } from '@ant-design/icons';
import SelectRegion from './SelectRegion';
import WithAllWithoutGroupButton from './WithAllWithoutGroupButton';
import SelectMarket from './SelectMarket';
import ProductStatusGroupButton from './ProductStatusGroupButton';
import { ErrorTwoTone, MoreHorizTwoTone, PeopleAltTwoTone } from '@mui/icons-material';
import SelectPlatform from './SelectPlatform';


function defaultFormValues() {
  return {
    query: '',
    pltRegion: [],
    errors: null,
    competitors: null,
    mktId: [],
    isActive: null,
    enabled: null,
    pltId: []
  };
}

const FiltersBlock = ({ filters, onFilter, query, isLoading }) => {
  const [form] = useForm()
  // const { getFieldDecorator } = form;
  const [startUrlFilters, setStartUrlFilters] = useState({});

  const setValuesForm = useCallback(
    (values) => {
      form.setFieldsValue(values);
    },
    [form],
  );

  const handleApplyFilters = useCallback((values) => {
        
        const { filters: filtersValue, query: newQuery } = formDataToUrlData({ ...values, query: values?.query ? values.query.trim() : null});
        
        onFilter({...filtersValue }, newQuery)
        
  }, [onFilter]);

  const handleResetFilters = useCallback(() => {
    setValuesForm(defaultFormValues());
    handleApplyFilters();
  }, [form]);

  useEffect(() => {
    // const filters = { ...defaultFormValues() }
    const newFilters = urlFiltersToFormData(filters, query, defaultFormValues());
    setValuesForm(newFilters);
    setStartUrlFilters(newFilters);
    // console.log('NEWFILTERS', newFilters)
  }, [filters, query]); // eslint-disable-line

  // console.log('filters', filters)

  return (
    <LoadingBlock loading={isLoading}>

      <Form onFinish={handleApplyFilters} form={form}>
     
         <Row style={{marginTop:"20px"}}>
          <Col span={4}>
            <Form.Item name="isActive" initialValue={defaultFormValues().isActive}>
              <ProductStatusGroupButton title="Active" conditionIfTrue="Active" conditionIfFalse="Inactive" icon={<MoreHorizTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="enabled" initialValue={defaultFormValues().enabled}>
              <ProductStatusGroupButton title="Enabled" conditionIfTrue="Enabled" conditionIfFalse="Disabled" icon={ <LockTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="errors" initialValue={defaultFormValues().errors}>
              <WithAllWithoutGroupButton title="Errors" icon={<ErrorTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="competitors" initialValue={defaultFormValues().competitors}>
            <WithAllWithoutGroupButton title="Competitors" icon={<PeopleAltTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />} />
            </Form.Item>
          </Col>
          </Row>
          <Row>
          <Col span={4} >
            <Form.Item name="pltId" initialValue={defaultFormValues().pltId}>
            <SelectPlatform />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="pltRegion" initialValue={defaultFormValues().pltRegion}>
              <SelectRegion />
            </Form.Item>
          </Col>
          
          <Col span={4}>
            <Form.Item name="mktId" initialValue={defaultFormValues().mktId}>
            <SelectMarket />
            </Form.Item>
          </Col>
          </Row>
                <Row>
                <Col span={3}>
                <Form.Item>
                      <Button
                      htmlType='submit'
                      type='primary'
                      > Apply Filters 
                      </Button>
                </Form.Item>
                </Col>
                <Col span={3}>
                <Form.Item>
                        <Button
                        onClick={handleResetFilters}
                        type='primary'
                        icon={<UndoOutlined />}
                        > Reset Filters 
                        </Button>
                </Form.Item>
                </Col>
                </Row>
                <Divider />
      </Form>
    </LoadingBlock>
  );
}

export default FiltersBlock;
