import { rankWith, scopeEndsWith, or, schemaMatches, schemaTypeIs, schemaSubPathMatches, and, optionIs, hasType, isControl, isNumberControl } from '@jsonforms/core';

export default rankWith(
  10, //increase rank as needed
  // schemaTypeIs('number'),
  or(
    // scopeEndsWith('percent'),
    // scopeEndsWith('fixed'),
    // scopeEndsWith('resellGamivoTradeSettings'),
    // schemaSubPathMatches('#/items/properties/regions/items/properties', (schema) =>
    //   schemaTypeIs('object')(schema) &&
    //   schema.hasOwnProperty('ratioMin') &&
    //   schema.hasOwnProperty('ratioMax')
    // ),
    // schemaMatches('resellGamivoTradeSettings', (schema) =>
    //   schema.hasOwnProperty('items')
    // )
    // schemaSubPathMatches('resellGamivoCommissions.platformCommission.1.value.fixed', () => true),
    schemaSubPathMatches('resellTradeSettings.regions.ratioMin', () => true),
    // isNumberControl,

  )
);
