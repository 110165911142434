import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { LocalGroceryStoreTwoTone } from '@mui/icons-material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { resellMarketsFetch } from '../../../Reducers/resell';
import marketsToOptions from '../../../Utils/marketToOptions';

const { Option } = Select;

const SelectMarket = ({ onChange, value }) => {

  const dispatch = useDispatch();

  const { payload } = useSelector((state) => state.resell.markets, shallowEqual)

  const [optionsData, setOptionsData] = useState([])

  const marketsNames = payload && Object.keys(payload)

  useEffect(() => {
    
    setOptionsData(marketsToOptions(marketsNames, payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const markets = payload && Object.entries(payload)

  // console.log('payloadMarkets', payload)

  useEffect(() => {
    dispatch(resellMarketsFetch())
  }, [])

  return (
    <>
      <div>
      <LocalGroceryStoreTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>Markets</b>
      </div>
      <div>
       <Select
          value={value}
          mode="multiple"
          style={{ width: '73%' }}
          placeholder="Select"
          onChange={onChange}
          options={optionsData}
        >
          {/* {markets && markets?.map((market, index) => (
            <Option key={index} value={+market[1]}>{market[0]}</Option>
          ))} */}
        </Select>
      </div>
    </>
  );
}

export default SelectMarket;
