import { useState, useCallback } from 'react';
import { Radio, Select } from 'antd';
import './styles.scss';
import { Badge } from 'antd';
import { formDataToUrlData } from '../../Utils/filtersControls';

const { Option } = Select

function WithAllWithoutButton({ title, initValue, filters, onFilter, alertCount }) {
  const [curValue, setCurValue] = useState(initValue ? initValue : '');
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback(({ target: { value } }, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurValue(value);
    onFilter({...filters, [field]: preparedValue })
  }, [curValue, filters]);


  // count = item.price.alert count
  // list = record. rows = record.mktRows => rows.map => item => item.price.alert (reduce)
  // (acc, item) => acc + item.price.alert
  // function 
  // if (item.price.alert) alertCount++


  return (
    <div className="WithAllWithoutButton">
      <Badge count={alertCount} size='small' offset={[-62, -5]} overflowCount={10}>
      <span className="WithAllWithoutButton__text">{title}</span>
      </Badge>
      <Radio.Group onChange={(e) => handleChangeFilter(e, title)}  value={curValue}>
        <Radio.Button value="with">With</Radio.Button>
        <Radio.Button value="">All</Radio.Button>
        <Radio.Button value="without">Without</Radio.Button>
      </Radio.Group>

     
      
    </div>
  );
}
export default WithAllWithoutButton;
