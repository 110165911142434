import { Typography, Modal, Button } from 'antd'
import { JSONTree } from 'react-json-tree'
import theme from '../../Utils/jsonTheme'
import frc from 'front-end-common';
import './styles.scss';

const useBoolean = frc.hooks.useBoolean;

function PopupWithJson({ title, content }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  async function copy() {
      await navigator.clipboard.writeText(JSON.stringify(content))
  }

  return <>
    <Button
      disabled={!content}
      type="primary"
      onClick={open}
    >
      {title}
    </Button>
    <Modal visible={isOpen} destroyOnClose closable onCancel={close} footer={null} width={"90vw"} height={"90vh"} centered style={{maxWidth: "500px"}} >
      {
        Array.isArray(content)
          ? content.map((item) => <Typography key={item}>
              <Button
                disabled={!content}
                type="seconary"
                onClick={copy}
              >
                Copy
              </Button>
              <JSONTree hideRoot theme={theme} data={item} />
            </Typography>)
          : <Typography>
              <Button
                disabled={!content}
                type="seconary"
                onClick={copy}
              >
                Copy
              </Button>
              <JSONTree shouldExpandNode={() => true} hideRoot theme={theme} data={content} />
          </Typography>
      }
    </Modal>
  </>;
}

export default PopupWithJson;
