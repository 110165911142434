import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Popconfirm, Button, message } from 'antd';
import { changeActions, changeActionsG2A, changeActionsGamivo } from '../../../Reducers/resell';

const SetActions = ({
                     afterUpdate,
                     selected 
                   }) => {

  const dispatch = useDispatch();

  const submitTotal = useCallback((data) => {
    const rowsArr = selected.map((item) => {
      const rowArr = item.split('_')
      return {
        'pltId': +rowArr[0],
        'pltProductId': rowArr[1],
        'pltRegion': rowArr[2],
        ...data
      }
    }) 

    dispatch(changeActions(rowsArr)).then(() => {
      message.success('Actions have been changed!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t change actions.');
    });
  }, [dispatch, afterUpdate, selected]);

  const submitG2A = useCallback((data) => {
    const rowsArr = selected.map((item) => {
      const rowArr = item.split('_')
      return {
        'pltId': +rowArr[0],
        'pltProductId': rowArr[1],
        'pltRegion': rowArr[2],
        ...data
      }
    }) 

    dispatch(changeActionsG2A(rowsArr)).then(() => {
      message.success('Actions have been changed!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t change actions.');
    });
  }, [dispatch, afterUpdate, selected]);

  const submitGamivo = useCallback((data) => {
    const rowsArr = selected.map((item) => {
      const rowArr = item.split('_')
      return {
        'pltId': +rowArr[0],
        'pltProductId': rowArr[1],
        'pltRegion': rowArr[2],
        ...data
      }
    }) 

    dispatch(changeActionsGamivo(rowsArr)).then(() => {
      message.success('Actions have been changed!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t change actions.');
    });
  }, [dispatch, afterUpdate, selected]);
  
  return (
    <>
      <div className='actionToggle'>
        <span className='actionToggle__text' style={ !selected.length ? { color: '#00000040' } : {} }>G2A</span>
        <Popconfirm
          title={`Are you sure to turn off ${selected.length} G2A ${selected.length === 1 ? 'product' : 'products'}?`}
          onConfirm={() => submitG2A({'isActive': false})}
          okText="Yes"
          cancelText="No"
        >
          <Button
            disabled={!selected.length} type="primary">Off</Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to turn on ${selected.length} G2A ${selected.length === 1 ? 'product' : 'products'}?`}
          onConfirm={() => submitG2A({'isActive': true})}
          okText="Yes"
          cancelText="No"
        >
          <Button disabled={!selected.length} type="primary">On</Button>
        </Popconfirm>
      </div>

      <div className='actionToggle'>
        <span className='actionToggle__text' style={ !selected.length ? { color: '#00000040' } : {} }>GAMIVO</span>
        <Popconfirm
          title={`Are you sure to turn off ${selected.length} GAMIVO ${selected.length === 1 ? 'product' : 'products'}?`}
          onConfirm={() => submitGamivo({'isActive': false})}
          okText="Yes"
          cancelText="No"
        >
          <Button disabled={!selected.length} type="primary">Off</Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to turn on ${selected.length} GAMIVO ${selected.length === 1 ? 'product' : 'products'}?`}
          onConfirm={() => submitGamivo({'isActive': true})}
          okText="Yes"
          cancelText="No"
        >
          <Button disabled={!selected.length} type="primary">On</Button>
        </Popconfirm>
      </div>

      <div className='actionToggle'>
        <span className='actionToggle__text' style={ !selected.length ? { color: '#00000040' } : {} }>TOTAL</span>
        <Popconfirm
          title={`Are you sure to turn off ${selected.length} ${selected.length === 1 ? 'product' : 'products'} for G2A and GAMIVO?`}
          onConfirm={() => submitTotal({'isActive': false})}
          okText="Yes"
          cancelText="No"
        >
          <Button  disabled={!selected.length} type="primary">Off</Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to turn on ${selected.length} ${selected.length === 1 ? 'product' : 'products'} for G2A and GAMIVO?`}
          onConfirm={() => submitTotal({'isActive': true})}
          okText="Yes"
          cancelText="No"
        >
          <Button disabled={!selected.length} type="primary">On</Button>
        </Popconfirm>
      </div>

    </>
  );
};

export default React.memo(SetActions);
