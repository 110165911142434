export default function clearSpecialCharacters(str) {
  if (str) {
    return str
      .replace(/'/g, ' ')
      .replace(/’/g, ' ')
      .replace(/"/g, ' ')
      .replace(/«/g, ' ')
      .replace(/»/g, ' ')
      .replace(/‘/g, ' ')
      .replace(/“/g, ' ')
      .replace(/”/g, ' ')
      .replace(/„/g, ' ')
      .replace(/[^\w\s-]/gi, '')
      .replace(/\s+/g, ' ')
      .trim();
  }
  return str;
}
