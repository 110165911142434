import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import Logout from '../../Logout';

const AntHeader = Layout.Header;

function Header() {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();

  const [current, setCurrent] = useState(
    localStorage.getItem('selectedKey') || 'resell'
  );

  const onClick = (e) => {
    const selectedKey = e.key;
    setCurrent(selectedKey);
    localStorage.setItem('selectedKey', selectedKey);
  };

  useEffect(() => {
    localStorage.setItem('selectedKey', current);
  }, [current]);

  return (
    <AntHeader className="Header" router={{ location, navigate, params }}  >
      <Menu
          defaultSelectedKeys={['resell']}
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          theme="dark"
          className="Nav"
      >
        <Menu.Item key="resell">
          <Link to="/">Resell dashboard</Link>
        </Menu.Item>
        <Menu.Item key="settings">
          <Link to="/dashboard-settings">Settings</Link>
        </Menu.Item>
        <Menu.Item key="merchant">
          <a href="https://merchant-dashboard.hoteos.net/">Merchant dashboard</a>
        </Menu.Item>
      </Menu>
      <div className="Header__rightBlock">
        <Logout />
      </div>
    </AntHeader>
    
  );
}

export default React.memo(Header);
