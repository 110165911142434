import ApiService from './ApiService';

export default {
  fetchOrderedList({ filters, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key];
      }
    }
    return ApiService.get('/resell/offers', updatedParams);
  },

  getList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/accounts', updatedParams);
  },

  fetchPlatformsList() {
    return ApiService.get(`/resell/platforms`);
  },

  fetchMarketsList() {
    return ApiService.get(`/resell/markets`);
  },

  fetchStatus() {
    return ApiService.get(`/resell/status`);
  },

  fetchRegions() {
    return ApiService.get(`/resell/regions`);
  },

  changeRatio(params) {
    const { mktId, mktProductId, ratioMin, ratioMax } = params;
    return ApiService.patch('/resell/mapping', [{ mktId, mktProductId, ratioMin, ratioMax }]);
  },

  resetRatio(params) {
    const { mktId, mktProductId, setDefault = ["ratio"] } = params;
    return ApiService.patch('/resell/mapping', [{ mktId, mktProductId, setDefault }]);
  },

  addMapping(params) {
    const { mktId, mktProductId, pltId, pltProductId, pltRegion } = params;
    return ApiService.post('/resell/mapping', [{ mktId, mktProductId, pltId, pltProductId, pltRegion, hasStock: true }]);
  },

  deleteMapping(params) {
    const { mktId, mktProductId } = params;
    return ApiService.delete('/resell/mapping', [{ mktId, mktProductId }]);
  },

  setAction(params) {
    const { mktId, mktProductId, value } = params;
    return ApiService.patch(
      `/resell/mapping`, [{ "mktId": mktId, "mktProductId": mktProductId, "isActive": value }],
    );
  },

  changeRatios(data) {
    return ApiService.patch('/resell/mapping?multi=true', data);
  },

  resetRatios(data) {
    return ApiService.patch('/resell/mapping?multi=true', data);
  },

  setActions(data) {
    return ApiService.patch('/resell/mapping', data);
  },

  setActionsG2A(data) {
    return ApiService.patch('/resell/mapping?mktId=4', data);
  },

  setActionsGamivo(data) {
    return ApiService.patch('/resell/mapping?mktId=3', data);
  },

  fetchSettings() {
    // data
    return ApiService.get(`/resell/configs`);

    // return new Promise((resolve) => {
    //   setTimeout(resolve, 1000, mockSettings);
    // });
  },

  fetchSettingsSchema() {
    return ApiService.get(`/resell/configs/schema`)
  },
  fetchSettingsUISchema() {
    return ApiService.get(`/resell/configs/uischema`)
  },
  updateSettings(data) {
    return ApiService.put(`/resell/configs`, data);
  },

  kinguinLookup(q) {
    return ApiService.get(`${'/kinguin/resell/products'}`, { q })
  },
};
