import React, { useEffect } from 'react';
import { Select } from 'antd';
import { LanguageTwoTone } from '@mui/icons-material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { regionsFetch } from '../../../Reducers/resell';

const { Option } = Select;

const SelectRegion = ({ onChange, value }) => {

  const dispatch = useDispatch();

  const { payload } = useSelector((state) => state.resell.regions, shallowEqual)

  // console.log('payload', payload)

  useEffect(() => {
    dispatch(regionsFetch())
  }, [])

  return (
    <>
      <div>
      <LanguageTwoTone style={{fontSize: '12px', marginRight: '4px', color: 'rgb(24, 144, 255)'}} />
        <b>Regions</b>
      </div>
      <div>
       <Select
          value={value}
          mode="multiple"
          style={{ width: '83%' }}
          placeholder="Select"
          onChange={onChange}
        >
          {payload && payload?.map((region, index) => (
            <Option key={index} value={region}>{region}</Option>
          ))}
        </Select>
      </div>
    </>
  );
}

export default SelectRegion;
