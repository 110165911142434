import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Popconfirm, message } from 'antd';
import { MinusCircleTwoTone } from '@ant-design/icons';
import b_ from 'b_';
import { deleteMapping } from '../../Reducers/resell';

const DeleteMappingForm = ({
                      record,
                      afterUpdate
                   }) => {

  const b = b_.lock('deleteMapping');

  const [mktId, mktProductId] = record

  const dispatch = useDispatch();

  const submit = useCallback(() => {
    dispatch(deleteMapping({ mktId, mktProductId })).then(() => {
      message.success('Mapping has been deleted!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t delete mapping.');
    });
  }, [dispatch, afterUpdate]);
  
  return (
    <>
      <Popconfirm
        title={`Are you sure to delete?`}
        onConfirm={submit}
        okText="Yes"
        cancelText="No"
      >
        <span style={{ cursor: 'pointer', display: 'block' }}>
          <MinusCircleTwoTone className="ResellTable__delete" />
        </span>
      </Popconfirm>
    </>
  );
};

export default React.memo(DeleteMappingForm);
