import React, {useState, useCallback, useEffect, useMemo} from 'react';
import { Tooltip, Tag, message, Table, InputNumber, Button } from 'antd';
import b_ from 'b_';
import moment from 'moment'; 
import { LoadingOutlined, CheckCircleTwoTone, CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const b = b_.lock('SyncTable');

function SyncTable({
                      account,
                      list,
                      loading,
                    }) {

  const GetColumns = useCallback(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'accountName',
        key: 'accountName',
        width: 150,
        align: 'center',
      },
      {
        title: 'Description',
        dataIndex: 'status',
        key: 'descr',
        width: 150,
        align: 'center',
        className: 'inner-rows',
        render: (status) => {
          return <div className="inner-rows__wrapper">
          {  
            status.map((item) => <div key={item.startedAt} className="inner-row__content">
                {
                  !item.isCompleted
                  ? <Spin indicator={antIcon} />
                  : <CheckCircleTwoTone twoToneColor="#52c41a" />
                }
                <span style={{marginLeft: '10px'}}>{item.descr} </span>
              </div>
            )
          }
          </div>
        },        
      },
      {
        title: 'Total',
        dataIndex: 'status',
        key: 'total',
        width: 50,
        align: 'center',
        className: 'inner-rows',
        render: (status) => {
          return <div className="inner-rows__wrapper">
          {  
            status.map((item) => <span key={item.startedAt} className="inner-row__content">{item.total}</span>)
          }
          </div>
        }, 
      },
      {
        title: 'Succeed',
        dataIndex: 'status',
        key: 'succeed',
        width: 75,
        align: 'center',
        className: 'inner-rows',
        render: (status) => {
          return <div className="inner-rows__wrapper">
          {  
            status.map((item) => <span key={item.startedAt} className="inner-row__content">{item.succeed}</span>)
          }
          </div>
        }, 
      },
      {
        title: 'Errors',
        dataIndex: 'status',
        key: 'errors',
        width: 60,
        align: 'center',
        className: 'inner-rows',
        render: (status) => {
          return <div className="inner-rows__wrapper">
          {  
            status.map((item) => <span key={item.startedAt} className="inner-row__content">{item.errors}</span>)
          }
          </div>
        }, 
      },
      {
        title: 'Started at',
        dataIndex: 'status',
        key: 'startedAt',
        width: 84,        
        align: 'center',
        className: 'inner-rows',
        render: (status) => {
          return <div className="inner-rows__wrapper">
          {  
            status.map((item) => <span key={item.startedAt} className="inner-row__content">{moment(item.startedAt).format('HH:mm:ss')}</span>)
          }
          </div>
        }, 
      },
      {
        title: 'Completed at',
        dataIndex: 'status',
        key: 'completedAt',
        width: 108,
        align: 'center',
        className: 'inner-rows',
        render: (status) => {
          return <div className="inner-rows__wrapper">
          {  
            status.map((item) => <span key={item.startedAt} className="inner-row__content">{item.completedAt === 0 ? '-' : moment(item.completedAt).format('HH:mm:ss')}</span>)
          }
          </div>
        }, 
      },
    ]; 
  }, [loading, list])

  const [columns, setColumns] = useState(GetColumns());

  useEffect(() => {
      setColumns(GetColumns())
  }, [list, loading, GetColumns])

  let status = !!list.find((item) => item.isCompleted === false)

  const pagination = useMemo(() => ({
    pageSize: 50,
  }), []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  
  return (
    <>
      {!isOpen && (
        <Button type="link" onClick={() => toggleOpen()} style={{ padding: 0, display: "block" }}>
           <div>
                <CaretRightOutlined />
                <span style={{marginLeft: '5px', marginRight: '10px'}}>Show sync status with {account}</span>
                {
                  status
                  ? <Spin indicator={antIcon} />
                  : <CheckCircleTwoTone twoToneColor="#52c41a" />
                }
              </div>
        </Button>
      )}
      {isOpen && (
        <>
            <Button type="link" onClick={() => toggleOpen()} style={{ padding: 0 }}>
            <div>
                <CaretDownOutlined />
                <span style={{marginLeft: '5px', marginRight: '10px'}}>Close sync status with {account}</span>
                {
                  status
                  ? <Spin indicator={antIcon} />
                  : <CheckCircleTwoTone twoToneColor="#52c41a" />
                }
              </div>
            </Button>
            <Table
              className={b()}
              bordered
              dataSource={list}
              scroll={{ x: "max-content" }}
              size="middle"
              loading={loading}
              columns={columns}
              pagination={pagination}
              rowKey={(record) => `${record.accountName}`}
            />
        </>
      )}
    </>
  );
}

export default SyncTable;
