import React from 'react';
import { Radio } from 'antd';

function ActionButtons({ value, handleClick}) {
  return (
    <Radio.Group 
        onClick={(e) => handleClick(e.target.value)}
        value={value.toString()}
        optionType="button"
        buttonStyle="solid"
        >
          <Radio.Button  onClick={(e) => handleClick(e.target.value)} value="false" >Off</Radio.Button>
          <Radio.Button  onClick={(e) => handleClick(e.target.value)} value="true" >On</Radio.Button>
    </Radio.Group>
  );
}

export default ActionButtons;