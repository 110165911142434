import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { Typography, message, Divider } from 'antd';
import useTable from '../../Utils/useTable';
import { resellListFetch, resellPlatformsFetch, resellMarketsFetch, statusFetch } from '../../Reducers/resell';
import SyncTable from './SyncTable';
import ResellTable from './ResellTable';
import {Input} from "antd";
import EditRatios from './GroupEditing/EditRatios';
import ResetRatios from './GroupEditing/ResetRatios';
import SetActions from './GroupEditing/SetActions';
import WithAllWithoutButton from '../../Components/WithAllWithoutButton'
import FiltersBlock from '../../Components/Filters';

const { Search } = Input;

function Resell() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.resell.list, resellListFetch);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resellPlatformsFetch())
    .catch((error) => {
      message.error(error.message || error);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(resellMarketsFetch())
    .catch((error) => {
      message.error(error.message || error);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(statusFetch())
    .catch((error) => {
      message.error(error.message || error);
    });
  }, [dispatch]);

  let timerID = useRef(null);

  const fetchStatuses = useCallback(() => timerID.current = setInterval(() => {
    dispatch(statusFetch())
    .catch((error) => {
      message.error(error.message || error);
    });
  }, 60000), [statusFetch, dispatch])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
        clearInterval(timerID.current)
        timerID.current = null
        fetchStatuses()
    }   
    return () => {
        mounted = false;
        clearInterval(timerID.current)
        timerID.current = null
    };
}, [dispatch])

const [alertCount, setAlertCount] = useState(0)

const seeList = () => {
  let count = 0
  list.forEach(item => {
    if (Array.isArray(item.mktRows)) {
    item.mktRows.forEach((el) => {
      if (el.price.alert !== null) count++
    })
  }
  })
  return count
}

useEffect(() => {
  setAlertCount(seeList())
}, [alertCount])

  const {
    payload:statusPayload,
    isLoading:statusIsLoading
  } = useSelector(state => state.resell.status, shallowEqual);

  const { G2Astatus, gamivoStatus, kinguinStatus } = useMemo(() => ({
    G2Astatus: (statusPayload && statusPayload.g2a) || [],
    gamivoStatus: (statusPayload && statusPayload.gamivo) || [],
    kinguinStatus: (statusPayload && statusPayload.kinguin) || [],
  }), [statusPayload]);

  // console.log(G2Astatus)


  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  // console.log('selectedRow', selectedRow)

  const { payload } = useSelector(state => state.resell.list, shallowEqual);

  const {
    // list, 
    total 
  } = useMemo(
    () => ({
      // list: (payload && payload.page) || [],
      total: (payload && payload.total) || [],
    }),
    [payload]
  )

const finalCountRecords = useMemo(() => (selectedRow.length ? selectedRow.length : total), [selectedRow, total]);

console.log('TOTAL', finalCountRecords)

  return (
    <>        
      <Typography.Title>Resell dashboard</Typography.Title>
      <SyncTable
          account="G2A"
          list={G2Astatus}
          pagination={pagination}
          loading={statusIsLoading}
       />
      <SyncTable
          account="GAMIVO"
          list={gamivoStatus}
          pagination={pagination}
          loading={statusIsLoading}
       />
       <SyncTable
          account="KINGUIN"
          list={kinguinStatus}
          pagination={pagination}
          loading={statusIsLoading}
       />
      <Divider style={{marginTop: '10px', marginBottom: '30px'}} />
      <div className="buttonsGroup">
        <EditRatios  selected={selectedRow} afterUpdate={fetch} />
        <ResetRatios selected={selectedRow} afterUpdate={fetch} />
        <SetActions selected={selectedRow} afterUpdate={fetch} />
        {/* <WithAllWithoutButton title="errors" filters={filters} onFilter={onFilter} alertCount={alertCount}/> */}
        <FiltersBlock onFilter={onFilter} query={query} filters={filters} viewButton={false} />
      </div>
      <div>
        <Search defaultValue={query} placeholder="Input search text" className="mb" size="large" onSearch={onSearch}/>
      </div>

      <div style={{marginTop: '7px'}}>
        <b>{finalCountRecords}</b> {finalCountRecords === 1 ? 'product' : 'products'}
        </div>
      <ResellTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
      />
    </>
  );
}

export default Resell;
