import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import b_ from 'b_';
import Header from './Header';
import './styles.scss';

const { Content } = Layout;
const b = b_.lock('MainLayout');

function MainLayout() {
  return (
    <Layout className={b()}>
      <Layout>
        <Header />
        <Content className={b('content')}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
