import React from 'react';
import { Radio } from 'antd';

const WithAllWithoutGroupButton = ({ onChange, value, title, icon })  =>{
  return (
    <>
      <div>
        {icon}
        <b>{title}</b>
      </div>
      <div>
        <Radio.Group onChange={onChange} value={value}>
          <Radio.Button value="with">With</Radio.Button>
          <Radio.Button value={null}>All</Radio.Button>
          <Radio.Button value="without">Without</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
}

export default WithAllWithoutGroupButton;
