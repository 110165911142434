import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useBoolean from '../../Utils/useBoolean';
import { Drawer, Form, Button, Input, message, Select, Tooltip } from 'antd';
import { LoadingOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import b_ from 'b_';
import { addMapping, lookupKinguin } from '../../Reducers/resell';
import { debounce } from 'lodash';
import clearSpecialCharacters from '../../Utils/clearSpecialCharacters';

const { Option } = Select;

const AddMappingForm = ({
                      market,
                      platform,
                      record,
                      afterUpdate
                   }) => {

  const b = b_.lock('addMapping');

  const lookup = useSelector((state) => state?.resell?.lookKinguin, shallowEqual);

  const [mktId, pltId, pltProductId, pltRegion] = record

  const dispatch = useDispatch();

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const handleSuccess = useCallback((data) => {
    
    const mktProductId = data.mktProductId
    dispatch(addMapping({ mktId, mktProductId, pltId, pltProductId, pltRegion })).then(() => {
      message.success('Mapping has been added!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t add mapping.');
    });
    close();
  }, [dispatch, afterUpdate, close]);


  // For Kinguin market
  const onSearch = useCallback(
    (() => {
      const debouncedLookup = debounce((value) => {
        dispatch(lookupKinguin(value))
      }, 700);

      return (query) => {
        const cleared = clearSpecialCharacters(query);
        if (cleared.length >= 3) {
          debouncedLookup(cleared);
        }
      };
    })(),
    [],
  );

  return (
    <>
      <Drawer visible={isOpen} title="Create New market product ID" width={600} closable placement="right" onClose={close}>
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
            <Input disabled value={market}  addonBefore="Market" />
            <Input disabled value={mktId} />
            <Input disabled value={platform} addonBefore="Platform"  />
            <Input disabled value={pltProductId} addonBefore="Product ID"  />
            <Input disabled value={pltRegion} addonBefore="Region"  />
          <Form.Item
            label="New market product ID"
            name="mktProductId"  
            rules={[
              {
                required: true,
                message: 'please enter new market product ID',
              },
          ]}>
            {market === "Kinguin" ? <Select
                    showSearch
                    showArrow
                    placeholder="Input search query"
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={onSearch}
                    notFoundContent={
                      !lookup.isLoading ? (
                        <p>Empty list</p>
                      ) : (
                        <p>
                          <LoadingOutlined />
                        </p>
                      )
                    }
                    loading={lookup.isLoading}
                  >
                    
                    {lookup && lookup?.payload?.map((item) =>
                        <Option value={item.id} title={item.slug} key={item.id}>
                          {item.name.length > 60 ? 
                          <Tooltip placement="bottom"  title={item.name}>
                        {item.name}
                        </Tooltip> :
                        <>{item.name}</>
                          }
                        </Option>
                    
                    )}
                  </Select>
                  : <Input  />
                  }
          </Form.Item>
          <Form.Item>
            <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <span style={{ cursor: 'pointer', display: 'block' }} onClick={open}>
          <PlusCircleTwoTone className="ResellTable__add" />
      </span>
    </>
  );
};

export default React.memo(AddMappingForm);
