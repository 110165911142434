import { Routes, Route } from 'react-router-dom';
import RedirectGuestToSignInPage from '../../Components/RedirectGuestToSignInPage';
import RedirectUserToMainPage from '../../Components/RedirectUserToMainPage';
import Resell from '../../Pages/Resell';
import RedirectPage from '../../Pages/RedirectPage';
import './styles.css';
import DashboardSettings from '../../Pages/DashboardSettings';

function App() {
  return <div className="App">
    <Routes>
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/sign-in" element={<RedirectUserToMainPage/>} />
      {/* <Route path="/dashboard-settings" element={<DashboardSettings/>} /> */}
      <Route path="/" element={<RedirectGuestToSignInPage/>}>
        <Route
          path="/"
          element={<Resell />}
        />
        <Route
          path="/dashboard-resell"
          element={<Resell />}
        />
        <Route path="/dashboard-settings" element={<DashboardSettings/>} />
      </Route>
    </Routes>
  </div>;
}

export default App;
